<template>
  <dog-dialog
    ref="dogDialog"
    width="400px"
    :title="isEdit ? '编辑公司' : '新增公司'"
    @loading-confirm="confirm"
    @closed="closed"
  >
    <dog-form
      ref="dogForm"
      :form-data="formData"
      :form-options="formOptions"
    ></dog-form>
  </dog-dialog>
</template>

<script>
  import companyService from '@/api/service/company.js';

  export default {
    name: 'addCompanyDialog',
    data() {
      return {
        isEdit: false,
        formData: {},
        formOptions: [
          {
            type: 'input',
            formItem: {
              label: '公司名',
              prop: 'name',
              rules: [this.$formRules.required('请输入公司名')]
            }
          },
          {
            type: 'select',
            formItem: {
              label: '类型',
              prop: 'type',
              rules: [this.$formRules.required('请选择类型')]
            },
            attrs: {
              service: this.$enumService('companyType'),
              clearable: false
            }
          },
          {
            type: 'input',
            formItem: {
              label: '简介',
              prop: 'desc',
              rules: []
            },
            attrs: {
              type: 'textarea',
              rows: 3
            }
          }
        ]
      };
    },
    methods: {
      openFrame(id) {
        this.$refs.dogDialog.open();
        if (id) {
          this.isEdit = true;
          companyService.getCompanyDetail({ id }).then((res) => {
            this.formData = res;
          });
        }
      },
      confirm(done) {
        this.$refs.dogForm
          .validate()
          .then(() => {
            return companyService[
              this.isEdit ? 'editCompanySimple' : 'addCompanySimple'
            ](this.formData);
          })
          .then(() => {
            this.$message.success('保存成功！');
            done();
            this.$emit('confirm');
            this.close();
          })
          .catch(done);
      },
      close() {
        this.$refs.dogDialog.close();
      },
      closed() {
        this.isEdit = false;
        this.formData = {};
      }
    }
  };
</script>

<style lang="scss" scoped></style>
