<template>
  <div class="flex_column companyList">
    <dog-search
      ref="search"
      :config="searchConfig"
      @search="search"
    ></dog-search>
    <dog-table
      ref="dogTable"
      id="1d23a7e6-7376-49b4-b11e-816242b6e850"
      :columns="tableColumns"
      :service="getList"
      column-type="selection"
      @selection-change="selectedData = $event"
    >
      <permission-button slot="btn" :config="btnConfig"> </permission-button>
    </dog-table>
    <set-tenant-dialog
      ref="setTenantDialog"
      @confirm="searchData"
    ></set-tenant-dialog>
    <add-company-dialog
      ref="addCompanyDialog"
      @confirm="searchData"
    ></add-company-dialog>
  </div>
</template>

<script>
  import companyService from '@/api/service/company';
  import setTenantDialog from './setTenantDialog.vue';
  import addCompanyDialog from './addCompanyDialog.vue';

  export default {
    name: 'companyList',
    components: { setTenantDialog, addCompanyDialog },
    data() {
      return {
        searchConfig: [
          {
            type: 'input',
            label: '公司名',
            key: 'name'
          },
          {
            type: 'select',
            label: '类型',
            key: 'type',
            attrs: {
              service: this.$enumService('companyType')
            }
          }
        ],
        tableColumns: [
          {
            prop: 'operate',
            label: '操作',
            width: 140,
            widthGrow: false,
            render: (h, { props: { row } }) => {
              return (
                <permission-element
                  config={[
                    {
                      code: 'editCompany',
                      callback: () =>
                        this.$refs.addCompanyDialog.openFrame(row.id)
                    },
                    {
                      code: 'setTenant',
                      callback: () =>
                        this.$refs.setTenantDialog.openFrame(row.id)
                    }
                  ]}></permission-element>
              );
            }
          },
          { prop: 'name', label: '公司名', width: 200 },
          { prop: 'type_cn', label: '类型', width: 80, widthGrow: false },
          {
            prop: 'desc',
            label: '简介',
            width: 200,
            render: (h, { props: { row } }) => (
              <div>
                {row.desc &&
                  row.desc.split('\n').map((item) => <div>{item}</div>)}
              </div>
            )
          }
        ],
        btnConfig: [
          {
            buttonType: 'primary',
            code: 'addCompany',
            callback: () => this.$refs.addCompanyDialog.openFrame()
          },
          {
            buttonType: 'danger',
            code: 'deleteCompany',
            callback: this.deleteCompany
          }
        ],
        selectedData: []
      };
    },
    mounted() {
      this.searchData();
    },
    methods: {
      searchData() {
        this.$refs.search.search();
      },
      search(query) {
        this.$refs.dogTable.search(query);
      },
      getList(params) {
        return companyService.getCompanyList(params);
      },
      deleteCompany() {
        if (this.selectedData.length === 0) {
          return this.$message.error('请至少选择一条数据！');
        }
        this.$confirm(`是否确定删除所选数据？`, '提示', {
          type: 'warning'
        }).then(() => {
          companyService
            .deleteCompanies({ ids: this.selectedData.map((item) => item.id) })
            .then(this.searchData);
        });
      }
    }
  };
</script>

<style lang="scss" scoped></style>
